@font-face {
    font-family: 'montserrat';
    src: url('../assets/fonts/montserrat/Montserrat-Regular.ttf');

  }

  @font-face {
    font-family: 'montserrat';
    src: url('../assets/fonts/montserrat/Montserrat-Italic.ttf');
    font-style: italic;
  }

 

  @font-face {
    font-family: 'montserrat';
    font-weight: bold;
    src: url('../assets/fonts/montserrat/Montserrat-Bold.ttf');
  }

  @font-face {
    font-family: 'montserrat';
    font-weight: bold;
    font-style: italic;
    src: url('../assets/fonts/montserrat/Montserrat-BoldItalic.ttf');
  }
 

  @font-face {
    font-family: 'montserrat-semibold';
    font-weight: normal;
    font-style: normal;
    src: url('../assets/fonts/montserrat/Montserrat-SemiBold.ttf');
  }
  @font-face {
    font-family: 'montserrat-semibold';
    font-weight: normal;
    font-style: italic;
    src: url('../assets/fonts/montserrat/Montserrat-SemiBold.ttf');
  }

  @font-face {
    font-family: 'montserrat-medium';
    font-weight: normal;
    src: url('../assets/fonts/montserrat/Montserrat-Medium.ttf');
  }
  @font-face {
    font-family: 'montserrat-medium';
    font-weight: normal;
    font-style: italic;
    src: url('../assets/fonts/montserrat/Montserrat-MediumItalic.ttf');
  }

  @font-face {
    font-family: 'montserrat-light';
    font-weight: normal; 
    src: url('../assets/fonts/montserrat/Montserrat-Light.ttf');
  }
