/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'theme/fonts';

@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap");

body {
  font-family: "Roboto", sans-serif;
}


* {
  font-family: "montserrat-semibold" !important;
    // font-family: 'montserrat-bold' ;
}

span, h1, label{
    text-decoration-color: black;
    // color: black;
    font-size: large;
    font-family: "montserrat-semibold" !important;
    h1{
        font-size: 40px;
    }
}
h1,label{
  text-decoration-color: black;
  color: black;
  font-size: large;
  font-family: "montserrat-semibold" !important;
  h1{
      font-size: 40px;
  }
}

  .mat-row .mat-cell, table, .avatar-header, ion-item {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    cursor: pointer;    
    font-family: 'Montserrat-semibold';
  }
  // .mat-row:hover 
    // .mat-cell {
    //   border-color: currentColor;
    //   color: #000;
    //   background-color: #e57706;
    // }
  
  #SvgjsPath1129, .apexcharts-bar-area {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .demo-row-is-clicked {
    font-weight: bold;
  }
.black-side{
    height: 30px;
    width: 7px;
  }
th{
    // font-family: "montserrat-semibold";
    text-decoration: underline;
    font-size: 20px;
    text-decoration: black;
}
th.mat-header-cell {
  text-align: left;
  font-size: 15px;
  color: black;
  font-family: "montserrat-semibold" !important;
}
.mat-paginator-range-label, .mat-paginator-page-size-label {
  margin: 0 32px 0 24px;
  font-family: "montserrat-semibold";
  color: #000;
}
button{
  img{
    margin-right: 10px;
  }
  // color: white !important;
}

.mat-drawer-content {
  overflow: hidden !important;
  overflow-x: hidden !important;;
  overflow-y: hidden !important;;
}
.example-container[_ngcontent-cia-c402] {
  border: 0px solid #fff !important;
}
.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden !important;
  outline: 0;
  width: 970px;
  height: 300px;
  min-height: inherit;
  max-height: inherit;
}
.mat-form-field {
  font-size: 14px;
  width: 100%;
}
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button .mat-datepicker-toggle-default-icon, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button .mat-datepicker-toggle-default-icon {
  color: black !important;
  margin: auto;
}
// .mat-form-field-appearance-fill .mat-form-field-flex {
//   width: 520px;

// }
  .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #FFF !important;
  height: 50px;
  padding: 0px 8px 0px 24px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Roboto;
  font-size: 16px;
  color: #121212;
  text-decoration: none solid rgb(18, 18, 18);
  box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
}
// .mat-form-field-wrapper {
// }
  .mat-form-field-wrapper {
  position: absolute !important;
  margin-top: -25px !important;
  width: 0px !important;
  margin-left: -12px !important;
  margin-bottom: 25px !important;
}
.native-textarea , .sc-ion-textarea-md {
  height: 130px;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  // width: 535px;
  width: inherit;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #FFF !important;
  height: 50px !important;
  width: 400px !important;
  margin-bottom: 10px !important;
  margin-left: 10px !important;
}
  .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: 0.75em 0.75em 0 0.75em;
}
.title{    
  h1 {
    span{
      margin-left: 12px;
    } 
  }
}


td, th {
  max-width: 20px;
  justify-content: center;
  text-align: left;
}

.mat-dialog-container {
  border-radius: 20px !important;
}
// form[_ngcontent-owy-c404] ion-label[_ngcontent-owy-c404] {
//   font-family: 'montserrat-semibold' !important;
//   margin-left: 25px;
// }
// .generer[_ngcontent-qaq-c404] .button[_ngcontent-qaq-c404] {
//   touch-action: manipulation;
//   font-family: 'montserrat' !important;
// }